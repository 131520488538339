import { forwardRef } from "react";

export const Legal = forwardRef(({ className }, ref) => {
  return (
    <div className={`copyright-text ${className}`} ref={ref}>
      <h2>Mentions Légales</h2>
      <h3>1. Propriétés du site</h3>
      <p>
        Nautique yana
        <br />
        Adresse : 236 route de la Distillerie 97351 Matoury <br />
        Mail: nautique.yana@gmail.com <br />
        Tél: 0694965001
      </p>
      <h3>2. Hébergeur</h3>
      <p>
        Le site est hébergé par : Vercel.com <br />
        Adresse : 440 N Barranca Avenue #4133 Covina, CA 91723 United States{" "}
      </p>
      <h3>3. Propriété intellectuelle</h3>
      <p>
        Tous les contenus présents sur le site (textes, images, vidéos, logos,
        etc.) sont protégés par le droit d'auteur et sont la propriété exclusive
        de Nautique yana ou de leurs auteurs respectifs. Toute reproduction ou
        représentation, partielle ou totale, sans autorisation préalable est
        interdite.
      </p>
      <h3>4. Liens hypertextes</h3>
      <p>
        Le site peut contenir des liens vers d’autres sites. Nautique yana ne
        saurait être tenu responsable du contenu de ces sites tiers.
      </p>
      <h3>5. Données personnelles</h3>
      <p>
        Les données personnelles collectées via le formulaire de réservation
        sont utilisées uniquement à des fins de gestion des réservations.
        Conformément au RGPD, vous disposez d'un droit d'accès, de rectification
        et de suppression de vos données. Pour toute demande, veuillez nous
        contacter à nautique.yana@gmail.com.
      </p>
      <h3>6. Cookies et données personnelles</h3>
      <p>
        Le site emploie exclusivement des cookies pour son bon fonctionnement.
        Actuellement, aucun cookie de suivi ou publicitaire n'est employé.{" "}
        <br />
        Les informations personnelles collectées via le formulaire de
        réservation sont destinées à gérer vos réservations. <br />
        Conformément au Règlement Général sur la Protection des Données (RGPD),
        vous avez le droit d’accéder à vos données personnelles, de les modifier
        ou de les supprimer. Pour exercer vos droits, contactez-nous à
        nautique.yana@gmail.com.
      </p>
    </div>
  );
});
