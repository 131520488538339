import { useState, useRef, useEffect } from "react";
import { Legal } from "./legal";
import { Copyright } from "./copyright";

export const Footer = ({ style }) => {
  const [displayLegal, setDisplayLegal] = useState(false);
  const [displayCopyright, setDisplayCopyright] = useState(false);
  const legalRef = useRef(null);
  const copyrightRef = useRef(null);
  const legalClick = () => {
    setDisplayLegal((prevState) => !prevState);
  };
  const copyrightClick = () => {
    setDisplayCopyright((prevState) => !prevState);
  };
  useEffect(() => {
    if (displayLegal && legalRef.current) {
      const timer = setTimeout(() => {
        legalRef.current.scrollIntoView({ behavior: "smooth" });
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [displayLegal]);
  useEffect(() => {
    if (displayCopyright && copyrightRef.current) {
      const timer = setTimeout(() => {
        copyrightRef.current.scrollIntoView({ behavior: "smooth" });
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [displayCopyright]);

  return (
    <div style={style} id="footer">
      <div className="container text-center copyright">
        <button className="copyright-style">
          &copy; 2024 Design by TemplateWire
        </button>
        <button className="copyright-style" onClick={legalClick}>
          Mentions légales
        </button>
        <button className="copyright-style" onClick={copyrightClick}>
          Politique de confidentialité
        </button>
      </div>
      <Legal ref={legalRef} className={displayLegal ? "show" : ""} />
      <Copyright
        ref={copyrightRef}
        className={displayCopyright ? "show" : ""}
      />
    </div>
  );
};
