import { forwardRef } from "react";

export const Copyright = forwardRef(({ className }, ref) => {
  return (
    <div className={`copyright-text ${className}`} ref={ref}>
      <h2>Politique de Confidentialité</h2>
      <p>
        Votre vie privée est importante pour nous. Cette politique de
        confidentialité explique comment nous collectons, utilisons et
        protégeons vos données personnelles.
      </p>
      <h3>1. Données collectées</h3>
      <p>
        Nous collectons les informations suivantes lorsque vous remplissez notre
        formulaire de réservation :
      </p>
      <ul>
        <li>Nom</li>
        <li>Email</li>
        <li>Date de réservation</li>
      </ul>
      <h3>2. Utilisation des données</h3>
      <p>Nous utilisons vos données pour :</p>
      <ul>
        <li>Gérer votre réservation</li>
        <li>Vous envoyer une confirmation par email</li>
      </ul>
      <h3>3. Conservation des données</h3>
      <p>
        Nous conservons vos données pendant une durée de 2 ans après votre
        dernière interaction avec nous.
      </p>
      <h3>4. Droits des utilisateurs </h3>
      <p>
        Vous avez le droit d’accéder à vos données, de demander leur correction
        ou leur suppression. Pour exercer ces droits, veuillez nous contacter à
        nautique.yana@gmail.com.
      </p>
      <h3>5. Sécurité</h3>
      <p>
        Nous protégeons vos informations personnelles avec des mesures de
        sécurité appropriées. <br />
        En utilisant notre site, vous consentez à la collecte et à l'utilisation
        de vos données comme décrit dans cette politique.
      </p>
    </div>
  );
});
