import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  dateDeparture: "",
  dateArrival: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message, dateDeparture, dateArrival }, setState] =
    useState(initialState);
  const [dateError, setDateError] = useState(false);
  const dateDepartureSelected = new Date(dateDeparture);
  const dateArrivalSelected = new Date(dateArrival);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  const goodDateSelected =
    !dateDeparture || dateArrivalSelected > dateDepartureSelected;

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  useEffect(() => {
    setDateError(!goodDateSelected);
  }, [goodDateSelected]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name, email, message, dateDeparture);

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          clearState();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>En apprendre plus</h2>
                <p>
                  Remplissez le formulaire ci-dessous pour nous envoyer un
                  e-mail, et nous vous répondrons dans les plus brefs délais.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nom"
                        value={name}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="dateDeparture">
                    Réservez votre date de départ
                  </label>
                  <input
                    type="date"
                    id="dateDeparture"
                    name="dateDeparture"
                    className="form-control"
                    min="2024-10-01"
                    value={dateDeparture}
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                {/* add pop transition */}
                {dateDeparture && (
                  <div className="form-group">
                    <label htmlFor="dateDeparture">Votre date de départ</label>
                    <input
                      type="date"
                      id="dateArrival"
                      name="dateArrival"
                      className={`form-control ${dateError ? "error" : ""}`}
                      min="2024-10-02"
                      value={dateArrival}
                      onChange={handleChange}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                )}
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    value={message}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <div className="checkBoxContainer">
                  <input
                    type="checkbox"
                    id="checkbox"
                    name="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <p htmlFor="checkbox" className="checkBoxText">
                    J'accepte que mes informations soient collectées
                  </p>
                </div>
                <div className="btnContainer">
                  {goodDateSelected && (
                    <button
                      type="submit"
                      className="btn btn-custom btn-lg"
                      style={{
                        display:
                          goodDateSelected && isChecked ? "block" : "none",
                        // display: isChecked ? "block" : "none",
                      }}
                    >
                      Envoyer
                    </button>
                  )}
                  <button
                    className="btn btn-custom btn-lg message-error"
                    style={{
                      opacity: goodDateSelected ? 0 : 1,
                      cursor: goodDateSelected ? "default" : "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setDateError(true);
                    }}
                  >
                    Vérifiez vos dates
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Info de contact</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Adresse
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Téléphone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* make it available when got link */}
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
